const baseUrl = 'https://api.digital.gob.do/v1/territories' as const

interface IResponse<T> {
    valid: boolean,
    data: T
}

export interface Catalog {
    name: string;
    code: string;
    identifier: string;
}

export interface Province extends Catalog {
    regionCode: string;
}

export interface Municipality extends Catalog {
    provinceCode: string;
    regionCode: string;
}

// Sectors are Districts on the optic API
export interface Sector extends Catalog {
    municipalityCode: string;
    provinceCode: string;
    regionCode: string;
}

export interface Neighborhood extends Catalog {
    districtCode: string;
    municipalityCode: string;
    provinceCode: string;
    regionCode: string;
}

export async function getRegions(): Promise<Catalog[]> {
    const response = await fetch(`${baseUrl}/regions`)
    const result: IResponse<Catalog[]> = await response.json()
    if (result.valid) {
        return Array.isArray(result.data) ? result.data : [result.data]
    } else {
        throw new Error('Error fetching regions')
    }
}

export async function getProvinces(): Promise<Province[]> {
    const response = await fetch(`${baseUrl}/provinces`)
    const result: IResponse<Province[]> = await response.json()
    if (result.valid) {
        return Array.isArray(result.data) ? result.data : [result.data]
    } else {
        throw new Error('Error fetching provinces')
    }
}

export async function getMunicipalities(province: string): Promise<Municipality[]> {
    const response = await fetch(`${baseUrl}/municipalities?provinceCode=${province}`)
    const result: IResponse<Municipality[]> = await response.json()
    if (result.valid) {
        return Array.isArray(result.data) ? result.data : [result.data]
    } else {
        throw new Error('Error fetching municipalities')
    }
}

// Sectors are Districts on the optic API
export async function getSectors(province: string, municipality: string): Promise<Sector[]> {

    const response = await fetch(`${baseUrl}/districts?provinceCode=${province}&municipalityCode=${municipality}`)
    const result: IResponse<Sector[]> = await response.json()
    if (result.valid) {
        return Array.isArray(result.data) ? result.data : [result.data]
    } else {
        throw new Error('Error fetching sectors')
    }
}

export async function getNeighborhoods(province: string, municipality: string, sector: string): Promise<Neighborhood[]> {
    const response = await fetch(`${baseUrl}/neighborhoods?provinceCode=${province}&municipalityCode=${municipality}&districtCode=${sector}`)
    const result: IResponse<Neighborhood[]> = await response.json()
    if (result.valid) {
        return Array.isArray(result.data) ? result.data : [result.data]
    } else {
        throw new Error('Error fetching neighborhoods')
    }
}


